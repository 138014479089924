<template>
  <div class="card">
    <div class="card-header">
      <h4>Payroll Penalty</h4>
    </div>
    <div class="card-body">
      <div class="form-row">
        <div class="form-group mb-0 col">
          <label>Alpha / Day</label>
          <h5>
            {{ data.alpha_presence_count }} / Day = Rp.
            {{ formatPrice(data.alpha_presence_cut) }}
          </h5>
        </div>
        <div class="form-group mb-0 col">
          <label>Late Presence / Hour</label>
          <h5>
            {{ data.late_presence_count }} / Hour = Rp.
            {{ formatPrice(data.late_presence_cut) }}
          </h5>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group mb-0 col">
          <label>Debt</label>
          <h5>Rp. {{ formatPrice(data.debt_cut) }}</h5>
        </div>
        <div class="form-group mb-0 col">
          <label>Food And Beverage Cut</label>
          <h5>Rp. {{ formatPrice(data.food_and_beverage_cut) }}</h5>
        </div>
      </div>
      <hr />
      <div class="form-group mb-0">
        <label>Total Penalty</label>
        <h5>Rp. {{ formatPrice(penaltyTotal) }}</h5>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["data"],

  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
  computed: {
    penaltyTotal() {
      return (
        this.data.debt_cut +
        this.data.food_and_beverage_cut +
        this.data.alpha_presence_cut +
        this.data.late_presence_cut
      );
    },
  },
};
</script>
