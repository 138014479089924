<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">Payroll</div>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>

    <div class="section-body">
      <base-title></base-title>
      <div class="row">
        <div class="col-md-5">
          <detail-show :data="form"></detail-show>
          <payroll-info :data="form"></payroll-info>
        </div>
        <div class="col-md-7">
          <absence-show :data="form"></absence-show>
          <payroll-penalty :data="form"></payroll-penalty>
          <payroll-bonus :data="form"></payroll-bonus>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import { apiGet } from "../../services/api";
import DetailShow from "./DetailShow.vue";
import AbsenceShow from "./AbsenceShow.vue";
import PayrollInfo from "./PayrollInfo.vue";
import PayrollPenalty from "./PayrollPenalty.vue";
import PayrollBonus from "./PayrollBonus.vue";

export default {
  name: "FormPayroll",
  components: {
    DetailShow,
    PayrollInfo,
    PayrollPenalty,
    PayrollBonus,
    AbsenceShow,
  },
  data() {
    return {
      name: "Form Payroll",
      form: [],
      monthyear: "",
      isSubmitting: false,
      errors: {},
      venues: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let id = this.$route.params.id;
      apiGet("payroll/" + id)
        .then((result) => {
          this.form = result.data.data;

          this.monthyear =
            String(result.data.data.year) +
            "-" +
            String(result.data.data.month);
          apiGet("venue").then((result) => (this.venues = result.data.data));
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  updated() {
    this.form.month = this.getThisMonth;
    this.form.year = this.getThisYear;
  },
  computed: {
    getThisYear() {
      let monthyear = this.monthyear.split("-")[0];
      return monthyear;
    },
    getThisMonth() {
      let monthyear = this.monthyear.split("-")[1];
      return monthyear;
    },
  },
};
</script>
