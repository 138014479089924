<template>
  <div class="card">
    <div class="card-header">
      <h4>Person Information</h4>
    </div>
    <div class="card-body">
      <div class="form-row">
        <div class="form-group col-md-6">
          <label>Total Presence / Day</label>
          <h5>
            {{ data.presence_count }}
          </h5>
        </div>
        <div class="form-group col-md-6">
          <label>Total Overtime / Hour</label>
          <h5>
            {{ data.overtime_count }}
          </h5>
        </div>
        <hr />
        <div class="form-group col-md-6">
          <label>Total Alpha / Day</label>
          <h5>{{ data.alpha_presence_count }}</h5>
        </div>
        <div class="form-group col-md-6">
          <label>Total Late / Hour</label>
          <h5>{{ data.late_presence_count }}</h5>
        </div>
        <div class="form-group col-md-12">
          <label>BPJS</label>
          <h5>Rp. {{ formatPrice(data.bpjs) }}</h5>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["data"],
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>
