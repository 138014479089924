<template>
  <div class="card">
    <div class="card-header">
      <h4>Absence & BPJS Information</h4>
    </div>
    <div class="card-body">
      <div class="form-group">
        <label>UMK</label>
        <h5>Rp. {{ formatPrice(data.umk) }}</h5>
      </div>
      <div class="form-group">
        <label>THR</label>
        <h5>Rp. {{ formatPrice(data.thr) }}</h5>
      </div>
      <div class="form-group">
        <label>Payroll</label>
        <h5>Rp. {{ formatPrice(data.payroll) }}</h5>
      </div>
      <hr />
      <div class="form-group">
        <label>Total Payroll Periodic</label>
        <h5>Rp. {{ formatPrice(data.total_payroll) }}</h5>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["data"],
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>
