<template>
  <div class="card">
    <div class="card-header">
      <h4>Payroll Bonus</h4>
    </div>
    <div class="card-body">
      <div class="form-row">
        <div class="form-group mb-0 col-6">
          <label>Overtime Count</label>
          <h5>
            {{ data.overtime_count }} / Hour = Rp.
            {{ formatPrice(data.overtime_bonus) }}
          </h5>
        </div>
        <div class="form-group mb-0 col-6">
          <label>Other Bonus</label>
          <h5>Rp. {{ formatPrice(data.bonus_other) }}</h5>
        </div>
        <div class="form-group mb-0 col-6">
          <label>Service Charge</label>
          <h5>Rp. {{ formatPrice(data.service_charge) }}</h5>
        </div>
        <div class="form-group mb-0 col-6">
          <label>Additional Payroll</label>
          <h5>Rp. {{ formatPrice(data.additional_payroll) }}</h5>
        </div>
      </div>
      <hr />
      <div class="form-group mb-0">
        <label>Total Bonus</label>
        <h5>Rp. {{ formatPrice(bonusTotal) }}</h5>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["data"],

  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
  computed: {
    bonusTotal() {
      return (
        this.data.bonus_other +
        this.data.service_charge +
        this.data.overtime_bonus +
        this.data.additional_payroll
      );
    },
  },
};
</script>
